import React, { useEffect, useRef, useState } from "react";
import TopNav from "../Home/TopNav";
import Footer from "../Home/Footer";
import { Container, Row, Col, Form, Spinner, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ApiHelper from "../../utils/ApiHelper";
import {
  baseUrlAietplBackend,
  baseUrlAietplImage,
  baseUrlAietplThumbnailImage,
} from "../../utils/ApiUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  cartcount,
  tempCartData,
  updateQunatityTempCartData,
} from "../Redux/action-creators";
import ResponsiveDialogBox from "../Common/ResponsiveDialogBox";
import ReactHtmlParser from "html-react-parser";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProductDetails = () => {
  const params = useParams();
  const logindata = useSelector((state) => state.loginreducer);
  const cartdata = useSelector((state) => state.cartreducer);
  const dispatch = useDispatch();
  const [productDetail, setproductDetail] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [moreDesc, setMoreDesc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [image, setimage] = useState("");
  const [detailedRequirement, setdetailedRequirement] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogBox, setDialogBox] = useState({
    status: false,
    message: "",
  });
  useEffect(() => {
    window.scroll(0, 0);
    productDetailById();
  }, []);

  const productDetailById = async () => {
    setIsLoading(true);
    const detail = await ApiHelper.getProductById(params.id);
    setproductDetail(detail);
    // console.log(detail.thumbnailFilenames[0]);
    setimage(detail.imageFilenames[0]);
    setIsLoading(false);
  };
  const addToCart = async () => {
    if (logindata.data.length !== 0) {
      let current = new Date();
      let date = `${current.getFullYear()}-0${current.getMonth() + 1
        }-${current.getDate()}`;
      const record = {
        quantity: quantity,
        userId: logindata.data.id,
        productMaster: {
          productId: productDetail.productId,
        },
        crDate: date,
        detailedRequirement: detailedRequirement,
      };
      setIsCartLoading(true);
      const addToEnquiry = await ApiHelper.addToEnquiry(
        record,
        logindata.data.accessToken
      );
      setIsCartLoading(false);
      if (addToEnquiry === "add product") {
        dispatch(cartcount(cartdata.cartCount + 1));
      }
    } else {
      const index = cartdata.tempdata?.findIndex(
        (prod) => prod.productId === productDetail?.productId
      );
      const findObjectById = cartdata.tempdata?.find(
        (obj) => obj.productId === productDetail?.productId
      );
      if (findObjectById) {
        dispatch(
          updateQunatityTempCartData(
            index,
            Number(findObjectById.quantity) + Number(quantity)
          )
        );
      } else {
        const record = {
          // cart_id: ++cartIdCounter,
          productName: productDetail.productName,
          productPrice: productDetail.productPrice,
          quantity: quantity,
          productDescription: productDetail.productDescription,
          fileName: productDetail?.imageFilenames[0],
          thumbnailFileName: productDetail?.thumbnailFilenames[0],
          productId: productDetail.productId,
          userId: logindata.data.id,
          stocks: productDetail.stocks,
          detailedRequirement: detailedRequirement,
        };
        dispatch(tempCartData(record));
      }

      setDialogBox({
        ...dialogBox,
        status: true,
      });
    }

    setDialogBox({
      ...dialogBox,
      status: true,
      message: "Product added to Enquiry",
    });
  };
  const closeModal = () => {
    setDialogBox({ ...dialogBox, status: false, message: "" });
  };

  return (
    <div>
      <TopNav />
      {isLoading ? (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <Spinner />
        </span>
      ) : (
        <>
          <Container fluid className="px-5">
            <Row className="py-5">
              <Col sm={6}>
                <div className="text-center product-details-wrapper p-3">
                  <img
                    className="img-fluid product-details-img abt-img"
                    src={baseUrlAietplImage + "/" + image}
                  />
                </div>

                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={true}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  // deviceType={this.props.deviceType}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {Array.isArray(productDetail?.imageFilenames) &&
                    productDetail?.imageFilenames?.map((list) => (
                      <>
                        <div className="preview-img-grid p-2" onClick={() => setimage(list)}>
                          {list && (
                            <img
                              src={baseUrlAietplImage + "/" + list}
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </>
                    ))}
                </Carousel>

              </Col>
              <Col sm={5} className="pt-2">
                <div className="px-3">
                  <h2 className="product-details-heading">
                    {productDetail && productDetail.productName}
                  </h2>
                  <Form.Label>Export By AIE TRADE</Form.Label>
                  <div className="py-2">
                    {/* <NavLink to={'/enquiry'}>
                      <button className='seemore-btn'>CONTACT NOW</button>
                      </NavLink> */}

                    {productDetail.productDescription &&
                      ReactHtmlParser(
                        productDetail.productDescription.substring(0, 600)
                      )}
                    {productDetail?.productDescription?.length > 600 && (
                      <a
                        onClick={() => setMoreDesc(true)}
                        style={{ cursor: "pointer" }}
                        className="viewmore pb-5"
                      >
                        Read More
                      </a>
                    )}
                  </div>
                  <hr />
                  <div className="py-2">
                    {/* {productDetail?.unitMaster?.unitName ? (
                      <p className="price-text mb-0">
                        {" "}
                        {productDetail.currency} {productDetail.productPrice}/
                        {productDetail?.unitMaster?.unitName}
                      </p>
                    ) : (
                      <p className="price-text mb-0">
                        {" "}
                        {productDetail.currency} {productDetail.productPrice}
                      </p>
                    )} */}
                    {productDetail.productPrice === 1 ? (
                      <p className="p-info-orange">Contact for Price</p>
                    ) : productDetail?.unitMaster?.unitName ? (
                      <>
                        <p className="price-text mb-0">
                          {productDetail.currency} {productDetail.productPrice} /
                          {productDetail?.unitMaster?.unitName}
                        </p>
                        <Form.Label className="mb-0">
                          Inclusive of all taxes
                        </Form.Label></>
                    ) : (
                      <><p className="price-text mb-0">
                        {productDetail.currency} {productDetail.productPrice}

                      </p><Form.Label className="mb-0">
                          Inclusive of all taxes
                        </Form.Label></>
                    )}

                    {productDetail.stocks > 0 ? (
                      <p className="p-instock pb-2">In Stock</p>
                    ) : (
                      <p className="p-outstock pb-2">Out Of Stock</p>
                    )}
                    <button
                      className="add-cartbtn"
                      onClick={() => setOpen(!open)}
                    >
                      Click Here To Add Detailed Requirement
                    </button>
                    {open && (
                      <Form.Group className="mb-3 mt-1">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Add your requirements"
                          value={detailedRequirement}
                          onChange={(e) =>
                            setdetailedRequirement(e.target.value)
                          }
                        />
                      </Form.Group>
                    )}
                  </div>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="2">
                      Quality
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        min={1}
                      />
                    </Col>
                    <Col sm="5">
                      <button
                        className="seedark-btn w-full"
                        variant="dark"
                        onClick={addToCart}
                        disabled={isCartLoading}
                      >
                        {isCartLoading && <Spinner />}
                        Add To Enquiry
                      </button>
                    </Col>
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer />
          {dialogBox.status && (
            // <DialogBox
            //   message={dialogBox.message}
            //   closeMessage="OK"
            //   closeModal={closeModal}
            // />
            <ResponsiveDialogBox closeModal={closeModal} />
          )}
          <Modal
            open={moreDesc}
            onClose={() => setMoreDesc(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className=" modal-size"
          >
            <Box sx={style} className="w-half">
              <IconButton
                aria-label="close"
                onClick={() => setMoreDesc(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Product Detail
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
              ></Typography>
              <Box className="overflow-modal ">
                <Box className="normaltxt">
                  {productDetail.productDescription &&
                    ReactHtmlParser(productDetail.productDescription)}
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ProductDetails;

import React, { useEffect, useState } from "react";
import TopNav from "../Home/TopNav";
import Footer from "../Home/Footer";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import ApiHelper from "../../utils/ApiHelper";
import { baseUrlAietplImage, baseUrlAietplThumbnailImage } from "../../utils/ApiUtils";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDialogBox from "../Common/ResponsiveDialogBox";
import {
  cartcount,
  tempCartData,
  updateQunatityTempCartData,
} from "../Redux/action-creators";
import ImageCarousel from "../Common/ImageCarousel";
// Dialog
import Modal from "react-bootstrap/Modal";

let cartIdCounter = 0;

let selectedCategory = [];

const Products = () => {
  // for modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (items, desc, pid) => {
    setShow(true);
    console.log(items, "items");
    // const itemImages = [];
    // if (items.fileName1) itemImages.push(items.fileName1);
    // if (items.fileName2) itemImages.push(items.fileName2);
    // if (items.fileName3) itemImages.push(items.fileName3);
    // if (items.fileName4) itemImages.push(items.fileName4);

    // console.log(itemImages, "itemImages");
      setCarouselItems(items?.imageFilenames);
      setDescriptionProp(desc);
      setProductIdProp(pid);
  };

  const logindata = useSelector((state) => state.loginreducer);
  const cartdata = useSelector((state) => state.cartreducer);
  const dispatch = useDispatch();
  const [selectedPriceRange, setSelectedPriceRange] = useState([0, 0]); // Initialize with a default price range
  const [priceTextPosition, setPriceTextPosition] = useState(0);

  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [isCartLoading, setIsCartLoading] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(null);
  const [dialogBox, setDialogBox] = useState({
    status: false,
    message: "",
  });
  const [categoryData, setCategorydata] = useState([]);
  const [wishlistedProduct, setWishlistedProduct] = useState([]);
  const [isWishlisting, setIsWishlisting] = useState(false);

  const [data, setdata] = useState([]);
  const [allProducts, setallProducts] = useState([]);

  const [carouselItems, setCarouselItems] = useState([]);

  const [descriptionProp, setDescriptionProp] = useState("");
  const [productIdProp, setProductIdProp] = useState("");

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  // const handlePopoverOpen = (event, items, desc, pid) => {
  //   setAnchorEl(event.currentTarget);
  //   console.log(items, "items");
  //   const itemImages = [];
  //   if (items.fileName1) itemImages.push(items.fileName1);
  //   if (items.fileName2) itemImages.push(items.fileName2);
  //   if (items.fileName3) itemImages.push(items.fileName3);
  //   if (items.fileName4) itemImages.push(items.fileName4);

  //   console.log(itemImages, "itemImages");
  //   setCarouselItems(items.thumbnailFilenames);
  //   setDescriptionProp(desc);
  //   setProductIdProp(pid);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    window.scroll(0, 0);
    getdata();
    getCategory();
    getWishlistedProduct();
  }, []);

  const getdata = async () => {
    setIsLoading(true);
    const listOfProduct = await ApiHelper.getProductsList();
    setdata(listOfProduct);
    setallProducts(listOfProduct);
    setIsLoading(false);
  };
  const closeModal = () => {
    setDialogBox({ ...dialogBox, status: false, message: "" });
  };
  const addToCart = async (item) => {
    if (logindata?.data.length !== 0) {
      let current = new Date();
      let date = `${current.getFullYear()}-0${
        current.getMonth() + 1
      }-${current.getDate()}`;
      const record = {
        quantity: 1,
        userId: logindata.data.id,
        productMaster: {
          productId: item.productId,
        },
        crDate: date,
        detailedRequirement: "",
      };

      setIsCartLoading(item.productId);

      const addToEnquiry = await ApiHelper.addToEnquiry(
        record,
        logindata.data.accessToken
      );
      setIsCartLoading(null);
      if (addToEnquiry === "add product") {
        dispatch(cartcount(cartdata.cartCount + 1));
      }
    } else {
      const index = cartdata.tempdata?.findIndex(
        (prod) => prod.productId === item.productId
      );
      const findObjectById = cartdata.tempdata?.find(
        (obj) => obj.productId === item.productId
      );
      if (findObjectById) {
        dispatch(
          updateQunatityTempCartData(index, Number(findObjectById.quantity) + 1)
        );
      } else {
        const record = {
          cart_id: ++cartIdCounter,
          productName: item.productName,
          productPrice: item.productPrice,
          quantity: 1,
          productDescription: item.productDescription,
          fileName: item?.imageFilenames[0],
          thumbnailFileName: item?.thumbnailFilenames[0],
          productId: item.productId,
          userId: logindata.data.id,
          stocks: item.stocks,
        };
        dispatch(tempCartData(record));
      }
    }
    setDialogBox({
      ...dialogBox,
      status: true,
      message: "Product added to Enquiry",
    });
  };
  const getCategory = async () => {
    ApiHelper.getCategory()
      .then((res) => {
        setCategorydata(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getWishlistedProduct = () => {
    if (logindata?.data.length !== 0) {
      ApiHelper.getWishlistedProductByUserId(
        logindata?.data?.id,
        logindata?.data?.accessToken
      )
        .then((res) => {
          setWishlistedProduct(res);
          setIsWishlisting(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addToWishListClick = async (pid) => {
    if (logindata.data.length !== 0) {
      setIsWishlisting(true);
      const records = {
        wishlisted: true,
        productMaster: {
          productId: pid,
        },
        user: {
          id: logindata?.data?.id,
        },
      };
      await ApiHelper.addToWishlist(records, logindata?.data?.accessToken)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      getWishlistedProduct();
    } else {
      history(`/login?wishlist=${pid}`);
    }
  };
  const removeFromWishlistClick = async (id) => {
    setIsWishlisting(true);
    ApiHelper.removeFromWishlist(id, logindata?.data?.accessToken).then(
      (res) => {
        getWishlistedProduct();
      }
    );
  };
  const getProductByCategory = async (e, categoryId) => {
    if (e.target.checked === true) {
      // Initialize an object to hold products by category
      const productsByCategory = {};

      // Iterate through allProducts and group them by category
      allProducts.forEach((product) => {
        const productCategoryId = product.productCategory.categoryId;

        if (!productsByCategory[productCategoryId]) {
          productsByCategory[productCategoryId] = [];
        }

        productsByCategory[productCategoryId].push(product);
      });

      // Push the new categoryId to the selectedCategory array
      selectedCategory.push(categoryId);

      // Get the products for the selected categories
      const selectedProducts = selectedCategory
        .map((categoryId) => productsByCategory[categoryId])
        .filter((products) => products !== undefined)
        .flat();

      console.log(selectedProducts.length);

      // Update the data with the selected products
      setdata(selectedProducts);
    } else {
      // Remove the categoryId from selectedCategory
      const updatedSelectedCategory = selectedCategory.filter(
        (selectedCategoryId) => selectedCategoryId !== categoryId
      );

      // Update the selectedCategory array
      selectedCategory = updatedSelectedCategory;

      // Update the state with the filtered products
      const filteredProducts = allProducts.filter((product) =>
        updatedSelectedCategory.includes(product.productCategory.categoryId)
      );

      setdata(filteredProducts);

      // If no categories are selected, show all products
      if (updatedSelectedCategory.length === 0) {
        setdata(allProducts);
      }
    }
  };
  const handlePriceRangeChange = (event) => {
    const selectedRange = [0, parseFloat(event.target.value)];
    console.log(selectedRange);

    const filteredProducts = allProducts.filter((product) => {
      const productPrice = parseFloat(product.productPrice);
      return (
        productPrice >= 0 && productPrice <= parseFloat(event.target.value)
      );
    });
    setdata(filteredProducts);

    setSelectedPriceRange(selectedRange);
    setPriceTextPosition(priceTextPosition);
  };

  return (
    <div>
      <TopNav />
      <div>
        <Container fluid className="px-5 pt-4">
          <div className="d-flex row">
            <div className="col-sm-3">
              <div className="filters">
                {/* button code */}
                <div className="px-2">
                  <Form.Label>Filters Sort By</Form.Label>
                  <div className="filter-grid p-2">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn-clear"
                        onClick={() => {
                          setdata(allProducts);
                          selectedCategory = [];
                          setSelectedPriceRange([0, 0]);
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                  </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Category</Accordion.Header>
                      <Accordion.Body>
                        {categoryData &&
                          categoryData.map((data, index) => (
                            <Form.Check
                              // inline
                              label={data.categoryName}
                              name="group1"
                              value={data.categoryId}
                              checked={selectedCategory.includes(
                                data.categoryId
                              )}
                              onChange={(e) =>
                                getProductByCategory(e, data.categoryId)
                              }
                            />
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Price Range</Accordion.Header>
                      <Accordion.Body>
                        <Form.Label>Select Price Range</Form.Label>
                        <div style={{ left: `${priceTextPosition}px` }}>
                          &#x20b9;0 To &#x20b9;{selectedPriceRange[1]}
                        </div>
                        <Form.Range
                          type="range"
                          min="0"
                          max="1000"
                          value={selectedPriceRange[1]}
                          onChange={handlePriceRangeChange}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="col-sm-9">
              <Row>
                {isLoading ? (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50vh",
                    }}
                  >
                    <Spinner />
                  </span>
                ) : Array.isArray(data) && data?.length !== 0 ? (
                  Array.isArray(data) &&
                  data?.map((item) => (
                    <Col sm={4} className="py-2">
                      <Card className="product-cards">
                        <NavLink to={"/product-details/" + item.productId}>
                          <Card.Img
                            className="img-fluid product-image"
                            variant="top"
                            src={baseUrlAietplImage + "/" + item?.imageFilenames[0]}
                            alt="product-images"
                            // onMouseEnter={(event) =>
                            //   // handlePopoverOpen(
                            //   //   event,
                            //   //   item,
                            //   //   item.productDescription,
                            //   //   item.productId
                            //   // )
                            //   handleShow(
                            //     item,
                            //     item.productDescription,
                            //     item.productId
                            //   )
                            // }
                            // onMouseLeave={handlePopoverClose}
                          />
                        </NavLink>
                        {/* <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          anchorPosition={{
                            top: 200,
                            left: 200,
                          }}
                        >
                          <div
                            style={{
                              width: "50vw",
                              height: "70vh",
                            }}
                          >
                            <IconButton onClick={handlePopoverClose}>
                              <CloseIcon />
                            </IconButton>

                            <ImageCarousel
                              items={carouselItems}
                              desc={descriptionProp}
                              productId={productIdProp}
                            />
                          </div>
                        </Popover> */}
                        <Modal
                          show={show}
                          onHide={handleClose}
                          animation={false}
                          centered
                          size="lg"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Product Details</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <ImageCarousel
                              items={carouselItems}
                              desc={descriptionProp}
                              productId={productIdProp}
                            />
                          </Modal.Body>
                        </Modal>
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <p className="p-info mb-0">{item.productName}</p>
                            <div
                              disabled={isWishlisting}
                              style={{
                                pointerEvents: isWishlisting ? "none" : "auto",
                              }}
                            >
                              {wishlistedProduct.map(
                                (wish, innerIndex) =>
                                  item.productId ===
                                    wish.productMaster.productId && (
                                    <div key={innerIndex}>
                                      {wish.wishlisted === false ? (
                                        <AiOutlineHeart
                                          className="prdctwish-icon"
                                          color="#d63031"
                                          onClick={() =>
                                            addToWishListClick(item.productId)
                                          }
                                        />
                                      ) : (
                                        <AiFillHeart
                                          className="prdctwish-icon"
                                          color="#d63031"
                                          onClick={() =>
                                            removeFromWishlistClick(
                                              wish.wishlistId
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  )
                              )}
                              {!wishlistedProduct.find(
                                (item1) =>
                                  item1.productMaster.productId ===
                                  item.productId
                              ) && (
                                <AiOutlineHeart
                                  className="prdctwish-icon"
                                  color="#d63031"
                                  onClick={() =>
                                    addToWishListClick(item.productId)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div>
                            {/* <p className="p-info-orange">
                              {item.currency} {item.productPrice}
                              {item?.unitMaster ? (
                                <span>/{item?.unitMaster?.unitName}</span>
                              ) : (
                                <span>/-</span>
                              )}
                            </p> */}
                            <p className="p-info-orange">
  {item.productPrice === 1 ? (
    <span size="sm">Contact for Price</span>
  ) : (
    <>
      {item.currency} {item.productPrice}
      {item?.unitMaster ? (
        <span>/{item?.unitMaster?.unitName}</span>
      ) : (
        <span>/-</span>
      )}
    </>
  )}
</p>

                          </div>
                          <div className="row">
                          <div className="py-2 col">
                            <Button
                              size="sm"
                              onClick={() => addToCart(item)}
                              disabled={isCartLoading === item.productId}
                            >
                              {isCartLoading === item.productId && <Spinner />}
                              Add to Enquiry
                            </Button>
                            </div>
                            <div className="py-2 col">
                            <Button
                              size="sm"
                              onClick={()=>handleShow(
                                item,
                                item.productDescription,
                                item.productId
                              )}
                            >
                             
                              Quick View
                            </Button>
                          </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <span>No product found</span>
                )}
              </Row>
            </div>
          </div>
          {/* <div className="d-flex row">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
            {data.ProductList && (
                  <ReactPaginate
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    activeClassName={"active"}
                    onPageChange={(event) => {
                      // onPageHandleChange(event.selected)

                      getdata(event.selected + 1);
                    }}
                    pageCount={Math.ceil(
                      data.totalProductList / productPerPage
                    )}
                    breakLabel="..."
                    previousLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "36px" }}
                      >
                        <AiFillLeftCircle />
                      </IconContext.Provider>
                    }
                    nextLabel={
                      <IconContext.Provider
                        value={{ color: "#B8C1CC", size: "36px" }}
                      >
                        <AiFillRightCircle />
                      </IconContext.Provider>
                    }
                  />
                )}
            </div>
          </div> */}
        </Container>
      </div>
      <Footer />
      {dialogBox.status && (
        // <DialogBox
        //   message={dialogBox.message}
        //   closeMessage="OK"
        //   closeModal={closeModal}
        // />
        <ResponsiveDialogBox closeModal={closeModal} />
      )}

      {/* {showCarousel && <ImageCarousel />} */}
    </div>
  );
};

export default Products;

import { Button, Card, Col, Container, Modal, Row, Table } from "react-bootstrap";
import SideNav from "../Sidebar/SideNav";
import { useEffect, useState } from "react";
import ApiHelper from "../../utils/ApiHelper";
import { Link, useNavigate } from "react-router-dom";
import {
  baseUrlAietplBackend,
  baseUrlAietplImage,
  baseUrlAietplThumbnailImage,
} from "../../utils/ApiUtils";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import DeleteBox from "../Common/DeleteBox";
import DialogBox from "../Common/DialogBox";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import ReactHtmlParser from "html-react-parser";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai"; // icons form react-icons
import { IconContext } from "react-icons";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

const ProductMasterList = () => {
  const history = useNavigate();
  const [data, setdata] = useState([]);
  const [imagesData, setimagesData] = useState({
    fileName1: "",
    fileName2: "",
    fileName3: "",
    fileName4: "",
  });
  const [openImages, setopenImages] = useState(false);
  const [deleteBox, setDeleteBox] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);
  const logindata = useSelector((state) => state.loginreducer);
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);
  const productPerPage = 5;

  // Function to handle displaying the full description
  const onViewFullDescription = (description) => {
    setFullDescription(description);  // Store the full description
    setShow(true);  // Open the modal
  };
  const removeHrTags = (html) => {
    return html.replace(/<hr[^>]*>/g, ''); // Removing <hr> tags (horizontal lines)
  };
  // State to store the full description
  const [fullDescription, setFullDescription] = useState("");


  useEffect(() => {
    window.scroll(0, 0);
    getdata(1);
  }, []);
  const getdata = async (pageNo) => {
    const list = await ApiHelper.getProductsListByPagination(
      pageNo,
      productPerPage,
      logindata.data.accessToken
    );
    setdata(list);
  };
  const onImageOpen = (data) => {
    setimagesData({
      ...imagesData,
      fileName1: data.thumbnailFileName1,
      fileName2: data.thumbnailFileName2,
      fileName3: data.thumbnailFileName3,
      fileName4: data.thumbnailFileName4,
    });
    setSelectedImageFiles(data.thumbnailFilenames)
    setopenImages(!openImages);
  };
  const onDeleteClick = async (id) => {
    setDeleteBox(true);
    setDeleteId(id);
  };
  const deleteRecords = () => {
    ApiHelper.deleteProductMaster(deleteId, logindata.data.accessToken).then(
      (res) => {
        setDeleteBox(false);
        setDeleteDialogBox(true);
        getdata(1);
      }
    );
  };
  const closeModal = () => {
    setDeleteBox(false);
    setDeleteDialogBox(false);
  };

  // Image Modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <SideNav />
      <Container>
        <div className="pb-4">
          <h2 className="form-heading">Products List</h2>
        </div>
        <div className="pb-5">
          <div className="d-flex justify-content-end pb-3">
            <Link to="/product-master">
              <button className="outlined-btn">Add Products</button>
            </Link>
          </div>
          <Card className="admin-form-cards">
            <Card.Body>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Unit</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Stock</th>
                    <th>Images</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.ProductList?.map((data, index) => (
                    <tr key={index}>
                      <td>{data.productName}</td>
                      {/* <td>
                        {data.productPrice} {data.currency}
                      </td> */}

                      <td>
                        {data.productPrice === 1 ? (
                          <p className="p-info-orange">
                            Contact for Price</p>
                        ) : (
                          `${data.productPrice} ${data.currency}`
                        )}
                      </td>
                      <td>{data.unitMaster?.unitName}</td>
                      <td>{data.productCategory.categoryName}</td>

                      <td width="40%">
                        {data.productDescription.length > 250 ? (
                          <>
                            {ReactHtmlParser(
                              data.productDescription.substring(0, 250)
                            )}
                            <button
                              className="btn btn-link"
                              onClick={() => onViewFullDescription(data.productDescription)}
                            >
                              Read More
                            </button>
                          </>
                        ) : (
                          ReactHtmlParser(data.productDescription)
                        )}
                      </td>

                      <td>{data.stocks}</td>
                      <td
                        className="text-center"
                        onClick={() => onImageOpen(data)}
                      >
                        <IconButton title="View Image">
                          <VisibilityTwoToneIcon />
                        </IconButton>
                      </td>
                      <td className="text-center">
                        <IconButton
                          onClick={(e) => {
                            history("/product-master/" + data.productId);
                            // onEditClick(data.unitId);
                          }}
                        >
                          <ModeEditOutlineTwoToneIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            onDeleteClick(data.productId);
                          }}
                        >
                          <DeleteTwoToneIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {deleteBox && (
                <DeleteBox
                  closeMessage="CANCEL"
                  closeModal={closeModal}
                  deleteRecord={deleteRecords}
                  successMessage="Delete"
                  message="Are you sure you want to delete?"
                />
              )}
              {deleteDialogBox && (
                <DialogBox
                  message="Product deleted successfully"
                  closeMessage="OK"
                  closeModal={closeModal}
                />
              )}
            </Card.Body>
          </Card>
        </div>
        {openImages && (
          <Modal
            show={openImages}
            onHide={setopenImages}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Product Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="mb-3">
                {selectedImageFiles.map(image =>
                  <Col md="4">

                    <img
                      src={
                        baseUrlAietplThumbnailImage +
                        "/" +
                        image
                      }
                      className="img-fluid"
                      alt="product"
                    />

                  </Col>)}
              </Row>
              {/* <Row className="mb-3">
                {imagesData.fileName1 && (
                  <Col md="6">
                    <div>
                      <img
                        src={
                          baseUrlAietplThumbnailImage +
                          "/" +
                          imagesData.fileName1
                        }
                        className="img-fluid"
                        alt="product"
                      />
                    </div>
                  </Col>
                )}
                {imagesData.fileName2 && (
                  <Col md="6">
                    <div>
                      <img
                        src={
                          baseUrlAietplThumbnailImage +
                          "/" +
                          imagesData.fileName2
                        }
                        className="img-fluid"
                        alt="product"
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                {imagesData.fileName3 && (
                  <Col md="6">
                    <div>
                      <img
                        src={
                          baseUrlAietplThumbnailImage +
                          "/" +
                          imagesData.fileName3
                        }
                        className="img-fluid"
                        alt="product"
                      />
                    </div>
                  </Col>
                )}
                {imagesData.fileName4 && (
                  <Col md="6">
                    <div>
                      <img
                        src={
                          baseUrlAietplThumbnailImage +
                          "/" +
                          imagesData.fileName4
                        }
                        className="img-fluid"
                        alt="product"
                      />
                    </div>
                  </Col>
                )}
              </Row> */}
            </Modal.Body>
          </Modal>


        )}
        {/* Modal for full description */}
        <Modal show={show} onHide={handleClose} >

          <Modal.Header closeButton>
            <Modal.Title>Full Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{ReactHtmlParser(fullDescription)}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        <Row className="py-3">
          {data.ProductList && (
            <ReactPaginate
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              activeClassName={"active"}
              onPageChange={(event) => {
                // onPageHandleChange(event.selected)

                getdata(event.selected + 1);
              }}
              pageCount={Math.ceil(data.totalProductList / productPerPage)}
              breakLabel="..."
              previousLabel={
                <IconContext.Provider
                  value={{ color: "#B8C1CC", size: "36px" }}
                >
                  <AiFillLeftCircle />
                </IconContext.Provider>
              }
              nextLabel={
                <IconContext.Provider
                  value={{ color: "#B8C1CC", size: "36px" }}
                >
                  <AiFillRightCircle />
                </IconContext.Provider>
              }
            />
          )}
        </Row>
      </Container>
    </>
  );
};

export default ProductMasterList;

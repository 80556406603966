import React from 'react'
import { Container , Row, Col, Form} from 'react-bootstrap'
import logo from '../../assets/images/logo.png'
import wlogo from '../../assets/images/aietpl-logo.webp'
import {FiPhoneCall} from 'react-icons/fi'
import {AiOutlineMobile,AiOutlineMail} from 'react-icons/ai'
import {TfiWorld} from 'react-icons/tfi'
import {MdNavigateNext} from 'react-icons/md'
const Footer = () => {
  return (
    <div>
        {/* <div className='bg-footer'>
            <Container fluid className='px-5'>
            <Row>
                <Col sm={4}>
               
                </Col>
                <Col sm={4}>
                <div className=''>
                <Form.Label>Contact Information</Form.Label>
                <div className='d-flex justify-content-start'>
               <FiPhoneCall className='my-1 footer-icons'/>
               <p className='mx-2 mb-0 p-info'>+91 022 40158412</p>
                </div>
                <div className='d-flex justify-content-start'>
               <AiOutlineMobile className='my-1 footer-icons'/>
               <p className='mx-2 mb-0 p-info '>09820211638</p>
                </div>
                <div className='d-flex justify-content-start'>
               <AiOutlineMail className='my-1 footer-icons'/>
               <p className='mx-2 mb-0 p-info'>biz@aietpl.com </p>
                </div>
                <div className='d-flex justify-content-start'>
               <TfiWorld className='my-1 footer-icons'/>
               <p className='mx-2 mb-0 p-info'>www.aietpl.com</p>
                </div>
                


                </div>
                </Col>
                <Col sm={4}>
                <Form.Label>Visit us at</Form.Label>
                <p className='p-info'>
                HD - 046, Oberoi Commerz II, 1st Floor CTS no 95, Off Western Express Highway, Oberoi Garden City, Goregaon East, Mumbai 400063.
                </p>

                </Col>
            </Row>
            </Container>

        </div> */}
        <section className='ai-footer' id="footer">
            <footer >
            <Container fluid className='px-5'>
            <div class="row text-start text-xs-center text-sm-left text-md-left">
				<div class="col-xs-12 col-sm-4 col-md-4">
                <div className='pb-3'>
                    <img src={wlogo} alt="logo-img" width="180px" />
                </div>
               <div className=''>
               <p className='p-info-white'>
                At AIETPL, we specialize in exporting a wide range of <br/>
                high-quality agro commodities, chemicals, and daily <br/>
                need items to clients worldwide. With our expertise <br/>
                and commitment to excellence, we aim to be your  trusted 
                partner in the global export import market.
                </p>
               </div>
				</div>
                <div class="col-xs-12 col-sm-4 col-md-4">
					<h5>Quick links</h5>
					<ul class="list-unstyled quick-links">
						<li><a href="/"><MdNavigateNext className='next-arrw' />Home</a></li>
						<li><a href="/about-us"><MdNavigateNext className='next-arrw' />About</a></li>
						<li><a href="#"><MdNavigateNext className='next-arrw' />FAQ</a></li>
						<li><a href="#"><MdNavigateNext className='next-arrw' />Get Started</a></li>
						<li><a href="#"><MdNavigateNext className='next-arrw' />Videos</a></li>
					</ul>
				</div>
                <div class="col-xs-12 col-sm-4 col-md-4">
					<h5>Quick links</h5>
					<ul class="list-unstyled quick-links">
						<li><a href="/"><MdNavigateNext className='next-arrw' />Home</a></li>
						<li><a href="#"><MdNavigateNext className='next-arrw' />About</a></li>
						<li><a href="#"><MdNavigateNext className='next-arrw' />FAQ</a></li>
						<li><a href="#"><MdNavigateNext className='next-arrw' />Get Started</a></li>
						<li><a href="#"><MdNavigateNext className='next-arrw' />Videos</a></li>
					</ul>
				</div>
				
			</div>
            
            </Container>
            </footer>
           
            
        </section>
        <div className='footer-bottom'>
<Container>
    <Row className='text-center'>
        <div>
            <p className='p-info-white py-3'>© Copyright 2023 - AIE TRADE</p>
        </div>
    </Row>
</Container>
            </div>
    </div>
  )
}

export default Footer